import { products, presenter } from '../data'
import Products from '../components/products'
import Hero from '../components/hero'
import { baseUrl } from '../lib/url'

function Error ({ products, statusCode }) {
  return (
    <>
      <div className='container mx-auto'>
        <div className='flex flex-col items-start content-center justify-center w-full p-10 pt-12 pb-24 mx-auto lg:p-0 lg:w-1/2'>
          <h1 className='my-4 text-xl font-bold lg:text-3xl'>404 - Tyvärr, sidan hittades inte</h1>
          <p className='lg:mb-10 leading-tight'>
            Du har försökt att nå en sida som inte finns.{' '}
            <a href={baseUrl('/')} className='text-blue-500 hover:underline'>
              Tillbaka till startsidan
            </a>{' '}
            eller till titta på några andra produkter.
          </p>
        </div>
      </div>
      <Hero id='nyapresenter' title='Urval av andra presenter' className='bg-yellow-300' />
      <div className='container mx-auto'>
        <Products products={products} />
      </div>
    </>
  )
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  const randomProducts = []

  for (let i = 0, l = 6; i < l; i++) {
    randomProducts.push(products[Math.floor(Math.random() * products.length)])
  }

  return { statusCode, products: randomProducts }
}

export default Error
